import settings from './travel_rankings/admin/index'
import hot_settings from './travel_rankings/admin/hot_settings'
import meta from './travel_rankings/reports/meta'
import pricing from './travel_rankings/reports/pricing'

export default {
  travel_rankings: {
    admin: {
      settings: settings,
      hot_settings: hot_settings
    },
    reports: {
      meta: meta,
      pricing: pricing
    }
  }
}