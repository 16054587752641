export default {
  seats_block_generators: {
    table: {
      name: 'Name',
      handlerStatus: 'Status',
      filePath: 'Report file',
      type: 'Type',
      description: 'Description'
    },
    form: {
      fields: {
        name: 'Name',
        type: 'Type',

        generateOptions__configId: 'Config Id',
        generateOptions__driverId: 'Driver Id',
        generateOptions__requireSeats: 'Seats',
        generateOptions__departureLocation: 'Departure',
        generateOptions__arrivalLocation: 'Arrival',
        generateOptions__startDate: 'Start',
        generateOptions__endDate: 'end',
        generateOptions__weekDays: 'Days',
        generateOptions__validatingSupplier: 'Validating sup.',
        generateOptions__flightNumber: 'Flight',
        generateOptions__rbd: 'Rbd',
        generateOptions__currency: 'Currency',
        generateOptions__issueOfficeId: 'Issue office',
        generateOptions__contractId: 'Contract Id',
        generateOptions__configKey: 'Config key',
        generateOptions__comment: 'Comment',
        generateOptions__checkPrice: 'Check price',
        generateOptions__checkConfigId: 'Check config id',
        generateOptions__locatorConfigId: 'Locator config id',
        generateOptions__fareConditions: 'Fare conditions',
        generateOptions__fares: 'Fares',

        generateOptions__fares__name: 'Brand name',
        generateOptions__fares__fare: 'Fare',
        generateOptions__fares__taxes: 'Taxes',
        generateOptions__fares__total: 'Total',
        generateOptions__fares__fareCode: 'Code',
        generateOptions__fares__baggage: 'Bag.',
        generateOptions__fares__baggageDetails__dimensionSum: 'size',
        generateOptions__fares__baggageDetails__dimensionUnit: 'in',
        generateOptions__fares__baggageDetails__weight: 'Weight',
        generateOptions__fares__baggageDetails__weightUnit: 'in',
        generateOptions__fares__baggageDetails__count: 'Count',

        generateOptions__features__isLcc: 'Lcc',
        generateOptions__features__isCharter: 'Charter',
        generateOptions__features__showSeatsCount: 'Show count',
        generateOptions__fileContent: 'File content'
      },
      options: {
        handlerStatus: {
          0: 'Undefined',
          1: 'Success',
          2: 'Error',
          3: 'Exception',
          4: 'Started',
        },
        type: {
          0: 'Group bookings',
          1: 'From file',
          'group_bookings': 'Group bookings',
          'from_file': 'From file'
        },
        generateOptions__driverId: {
          6: 'Sabre',
          'sabre': 'Sabre',
          52: 'FlyOne',
          'fly_one': 'FlyOne'
        }

      },
      legends: {
        settings: 'Settings',
        features: 'Features'
      },
      placeholder: {
        generateOptions__fares__baggageDetails__dimensionSum: 'Format: 10*10*10',
        generateOptions__fares__baggageDetails__dimensionUnit: 'CM/...',
        generateOptions__fares__baggageDetails__weightUnit: 'KG/...'
      },
    },
    messages:{
      download_error: 'Download link not ready',
      download_empty_content: 'File not valid'
    }
  }
}
