<template lang="pug">
  extends /form
  block fields

    fieldset(:class="`el-form-fieldset`")
      legend {{ translate('form.legends.general_data') }}
      div
        el-row
          ttn-input(
            v-model="temp['id']"
            v-bind="attr('id')"
          )
          el-col(:span='4' class='row')
            ttn-switch(
              v-model="temp['active']"
              v-bind="attr('active')"
              :active-text="translate('form.fields.active')"
              label='' label-width='0px'
            )
          el-col(:span='6' class='row')
            ttn-input(
              v-model="temp['priority']"
              v-bind="attr('priority')"
              :rules="inputValidations('priority')"
              label-width='65px' style='width:230px'
            )
          el-col(:span='14' class='row')
            ttn-multiselect(
              v-model="temp['gdsId']"
              v-bind="attr('gdsId')"
              :rules="inputValidations('gdsId')"
              label-width='150px'
            )

    fieldset(:class="`el-form-fieldset`")
      legend {{ translate('form.legends.flight_data') }}
      div
        el-row
          el-col(:span='10' class='row')
            ttn-input(
              v-model="temp['marketingSupplier']"
              v-bind="attr('marketingSupplier')"
              :rules="inputValidations('marketingSupplier')"
              label-width='150px' style='width:436px'
            )
          el-col(:span='14' class='row')
            ttn-input(
              v-model="temp['flightNumber']"
              v-bind="attr('flightNumber')"
              :rules="inputValidations('flightNumber')"
              label-width='150px'
            )
        ttn-input(
          v-model="temp['fareCode']"
          v-bind="attr('fareCode')"
          :rules="inputValidations('fareCode')"
          label-width='150px'
        )
        ttn-textarea(
          v-model="temp['geography']"
          v-bind="attr('geography')"
          :rules="inputValidations('geography')"
          label-width='150px'
        )

    fieldset(:class="`el-form-fieldset`" v-if="temp['details_decoded']")
      legend {{ translate('form.legends.baggage_data') }}
      div(v-for="baggageType in baggageTypes" :key="baggageType")
        fieldset(:class="[`el-form-fieldset`, `fieldset-${baggageType}`]")
          legend {{ translate(`form.legends.${baggageType}`) }}
          el-row
            ttn-radio-group(
              v-model="temp['details_decoded'][baggageType]['use']"
              v-bind="elAttr('info__use', `details_decoded.${baggageType}.info__use`)"
              :rules="inputValidations('use')"
              label='' label-width='0px' type="warning"
            )
          el-row(v-for="paxType in !isDisabledField('override', baggageType) && paxTypes")
            fieldset(:class="`el-form-fieldset`" ref="fieldsets")
              legend {{ paxType }}
              el-row
                el-col(:span='3' class='row')
                  ttn-input(
                    v-model="temp['details_decoded'][baggageType][paxType]['count']"
                    v-bind="elAttr('info__count', `details_decoded.${baggageType}.${paxType}.info__count`)"
                    :rules="inputValidations('count', baggageType, paxType)"
                    label-width='61px' style='width:130px'
                  )
                el-col(:span='3' class='row')
                  ttn-input(
                    v-model="temp['details_decoded'][baggageType][paxType]['weight']"
                    v-bind="elAttr('info__weight', `details_decoded.${baggageType}.${paxType}.info__weight`)"
                    :rules="inputValidations('weight', baggageType, paxType)"
                    label-width='61px' style='width:130px'
                  )
                el-col(:span='3' class='row')
                  ttn-select(
                    v-model="temp['details_decoded'][baggageType][paxType]['weight_unit']"
                    v-bind="elAttr('info__weightUnit', `details_decoded.${baggageType}.${paxType}.info__weight_unit`)"
                    :rules="inputValidations('weight_unit', baggageType, paxType)"
                    label-width='45px' style='width:132px'
                  )
                el-col(:span='3' class='row')
                  ttn-input(
                    v-model="temp['details_decoded'][baggageType][paxType]['length']"
                    v-bind="elAttr('info__length', `details_decoded.${baggageType}.${paxType}.info__length`)"
                    :rules="inputValidations('length', baggageType, paxType)"
                    label-width='61px' style='width:130px'
                  )
                el-col(:span='3' class='row')
                  ttn-input(
                    v-model="temp['details_decoded'][baggageType][paxType]['width']"
                    v-bind="elAttr('info__width', `details_decoded.${baggageType}.${paxType}.info__width`)"
                    :rules="inputValidations('width', baggageType, paxType)"
                    label-width='61px' style='width:130px'
                  )
                el-col(:span='3' class='row')
                  ttn-input(
                    v-model="temp['details_decoded'][baggageType][paxType]['height']"
                    v-bind="elAttr('info__height', `details_decoded.${baggageType}.${paxType}.info__height`)"
                    :rules="inputValidations('height', baggageType, paxType)"
                    label-width='61px' style='width:130px'
                  )
                el-col(:span='3' class='row')
                  ttn-input(
                    v-model="temp['details_decoded'][baggageType][paxType]['dimension_sum']"
                    v-bind="elAttr('info__dimensionSum', `details_decoded.${baggageType}.${paxType}.info__dimension_sum`)"
                    :rules="inputValidations('dimension_sum', baggageType, paxType)"
                    label-width='61px' style='width:130px'
                  )
                el-col(:span='3' class='row')
                  ttn-select(
                    v-model="temp['details_decoded'][baggageType][paxType]['dimension_unit']"
                    v-bind="elAttr('info__dimensionUnit', `details_decoded.${baggageType}.${paxType}.info__dimension_unit`)"
                    :rules="inputValidations('dimension_unit', baggageType, paxType)"
                    label-width='45px' style='width:132px'
                  )
              el-row
                el-col(:span='5' class='row')
                  el-button(
                    :class="`el-button-bd`"
                    @click="copyDetailsFromOther(baggageType, paxType, 'ADT')" type="primary"
                    v-if="isCopyButtonwVisible(baggageType, paxType, 'ADT')"
                  ) {{ translate('form.buttons.copy_adt') }}
                el-col(:span='5' class='row')
                  el-button(
                    :class="`el-button-bd`"
                    @click="copyDetailsFromOther(baggageType, paxType, 'CHD')" type="warning"
                    v-if="isCopyButtonwVisible(baggageType, paxType, 'CHD')"
                  ) {{ translate('form.buttons.copy_chd') }}
                el-col(:span='5' class='row')
                  el-button(
                    :class="`el-button-bd`"
                    @click="clearPaxDetails(baggageType, paxType)" type="danger"
                    v-if="isClearButtonwVisible(baggageType, paxType)"
                  ) {{ translate('form.buttons.clear') }}
          el-row(v-if="!isDisabledField('override', baggageType)")
            ttn-switch(
              v-model="temp['details_decoded'][baggageType]['override']"
              v-bind="elAttr('info__override', `details_decoded.${baggageType}.info__override`)"
              :active-text="translate('form.fields.override')"
              :disabled="isDisabledField('override', baggageType)"
              label='' label-width='0px'
            )
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  components: {
    BaseForm
  },
  extends: BaseForm,
  computed: {
    baggageTypes() { return ['baggage', 'carry_on', 'personal_item'] },
    paxTypes() { return ['ADT', 'CHD', 'INF'] },
    baseFields() { return ['count', 'weight'] },
    baseDimensionFields() { return ['width', 'height', 'length'] },
    allDimensionFields() { return ['width', 'height', 'length', 'dimension_sum'] },
    unitFields() { return ['weight_unit', 'dimension_unit'] }
  },
  methods: {
    async actualizeCallback(temp) {
      let newTemp = {}

      for (let i = 0; i < this.fields.length; i++) {
        let field = this.fields[i]
        if (field.name.indexOf('info__') == -1) {
          newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
        }
      }

      if (temp['baggageDetails']) {
        let details_decoded = {}
        const details = temp['baggageDetails']

        for (let baggageType of this.baggageTypes) {
          let bagItem = details[baggageType]
          details_decoded[baggageType] = {
            use:      (bagItem ? 'use' : 'skip'),
            override: !!(bagItem && bagItem['force'])
          }

          for (let paxType of this.paxTypes) {
            details_decoded[baggageType][paxType] = {}
            if (bagItem) {
              let paxItem = details[baggageType][paxType]
              for (let key of Object.keys(paxItem)) {
                details_decoded[baggageType][paxType][key] = paxItem[key].toString()
              }
            }
          }
        }

        newTemp['details_decoded'] = details_decoded
      }

      return newTemp
    },
    changeFieldValue(field, value) {
      if (field.name == 'info__use') {
        let baggageType = field.prop.split('.')[1]
        for (let paxType of this.paxTypes) {
          this.clearPaxDetails(baggageType, paxType)
        }
        this.temp['details_decoded'][baggageType]['override'] = false
      }
    },
    formatTemp() {
      if (this.temp['details_decoded']) {
        let result = {}
        const details = this.temp['details_decoded']

        for (let baggageType of this.baggageTypes) {
          let bagItem = details[baggageType]
          if (!bagItem || bagItem['use'] == 'skip' || this.paxTypes.some(x => !bagItem[x])) {
            continue
          }

          result[baggageType] = {}
          for (let paxType of this.paxTypes) {
            result[baggageType][paxType] = {}
            let paxItem = bagItem[paxType]
            for (let key of Object.keys(paxItem)) {
              let val = paxItem[key]
              if (val && val.length) {
                result[baggageType][paxType][key] = (this.unitFields.indexOf(key) != -1 ? val : parseInt(val))
              }
            }
          }
          if (bagItem.override) result[baggageType]['force'] = true
        }

        this.temp['baggageDetails'] = result
      }

      return this.temp
    },
    isDisabledField(field, legend) {
      if ((field == 'override') || (field.name.indexOf('info__') != -1 && field.name != 'info__use')) {
        let baggageType = (field == 'override' ? legend : field.prop.split('.')[1])
        return this.temp['details_decoded'][baggageType]['use'] == 'skip'
      } else {
        return false
      }
    },
    beforeCreateCallback() {
      this.temp = Object.assign({}, this.temp, { details_decoded: this.defaultOptions() })
    },
    defaultOptions() {
      let result = {}

      for (let baggageType of this.baggageTypes) {
        result[baggageType] = { use: 'use', override: false }
        for (let paxType of this.paxTypes) { result[baggageType][paxType] = {} }
      }

      return result
    },
    elAttr(fieldName, prop) {
      return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], { prop: prop }))
    },
    isCopyButtonwVisible(baggageType, paxType, baseType) {
      if (this.temp['details_decoded'][baggageType]['use'] == 'skip') { return false }
      let baseData = this.temp['details_decoded'][baggageType][baseType]
      return (paxType != 'ADT' && paxType != baseType && Object.keys(baseData).some(x => baseData[x] && baseData[x].length))
    },
    isClearButtonwVisible(baggageType, paxType) {
      if (this.temp['details_decoded'][baggageType]['use'] == 'skip') { return false }
      let paxData = this.temp['details_decoded'][baggageType][paxType]
      return (paxType != 'ADT' && Object.keys(paxData).some(x => paxData[x] && paxData[x].length))
    },
    copyDetailsFromOther(baggageType, paxType, baseType) {
      this.clearPaxDetails(baggageType, paxType)
      this.temp['details_decoded'][baggageType][paxType] = { ...this.temp['details_decoded'][baggageType][baseType] }
    },
    clearPaxDetails(baggageType, paxType) {
      this.temp['details_decoded'][baggageType][paxType] = {}
    },
    validateCombination(rule, value, callback) {
      if (value.length > 1 && value.indexOf('all') != -1) {
        callback(new Error(this.translate('form.validations.allGds')));
      } else {
        callback();
      }
    },
    inputValidations(field, baggageType, paxType) {
      let regexps = {
        default: /^([0-9]){1,}$/,
        integer: /^([0-9]){1,3}$/,
        count: /^([0-9]){1}$/,
        unit: /^([A-Z]){2}$/,
        marketingSupplier: /^([A-ZА-Я0-9]){2,3}$/,
        flightNumber: /^(\d{1,4}|\d{1,4}-\d{1,4})(,(\d{1,4}|\d{1,4}-\d{1,4}))*$/,
        fareCode: /^((\*)?([A-Z0-9\-\/])+(\*)?)(,((\*)?([A-Z0-9\-\/])+(\*)?))*$/,
        geography: /^(([A-Z]{2,3}|\*)(,([A-Z]{2,3}|\*))*)-(([A-Z]{2,3}|\*)(,([A-Z]{2,3}|\*))*)(;(([A-Z]{2,3}|\*)(,([A-Z]{2,3}|\*))*)-(([A-Z]{2,3}|\*)(,([A-Z]{2,3}|\*))*))*$/
      }
      let required = false
      let pattern = regexps.default
      let message = this.translate('form.validations.default')

      if (field == 'priority') {
        required = true
      } else if (field == 'gdsId') {
        return [
          { required: true, message: this.translate('form.validations.gdsId'), trigger: 'change' },
          { validator: this.validateCombination, trigger: 'change' }
        ]
      } else if (['marketingSupplier', 'flightNumber', 'fareCode', 'geography'].indexOf(field) != -1) {
        const val = this.temp[field]
        required = (field == 'marketingSupplier' ? true : val && val.length && !pattern.test(val))
        pattern = regexps[field]
        message = this.translate(`form.validations.${field}`)
      } else if (field == 'use') {
        required = this.baggageTypes.every(x => this.temp['details_decoded'][x]['use'] == 'skip')
        message = this.translate('form.validations.use')
      } else if (this.temp['details_decoded'][baggageType]['use'] == 'skip') {
        required = false
      } else {
        const row = this.temp['details_decoded'][baggageType][paxType]
        const val = row[field]
        const key = (this.unitFields.indexOf(field) != -1 ? 'unit' : (field == 'count' ? 'count' : 'integer'))
        pattern = regexps[key]
        message = this.translate(`form.validations.${key}`)

        if (val && val.length) {
          if (!pattern.test(val)) {
            required = true
          } else {
            if (key == 'integer' && val.length != parseInt(val).toString().length) {
              required = true
              message = this.translate('form.validations.notInteger')
            } else if (this.baseFields.indexOf(field) != -1 && this.baseFields.every(x => row[x] && regexps.integer.test(row[x])) && (val == 0) && !this.baseFields.every(x => row[x] == 0)) {
              required = true
              message = this.translate('form.validations.notZero')
            } else if (this.allDimensionFields.indexOf(field) != -1 && (val == 0)) {
              required = true
              message = this.translate('form.validations.notZero')
            }
          }
        } else if (!val || !val.length) {
          if (field == 'count') {
            required = !row['weight'] || !row['weight'].length
          } else if (field == 'weight') {
            required = (row['weight_unit'] && row['weight_unit'].length) || (!row['count'] || !row['count'].length)
          } else if (field == 'weight_unit') {
            required = row['weight'] && row['weight'].length
          } else if (this.baseDimensionFields.indexOf(field) != -1) {
            required = (
              this.baseDimensionFields.some(x => row[x] && row[x].length) ||
              (row['dimension_unit'] && row['dimension_unit'].length) &&
              (!row['dimension_sum'] || !row['dimension_sum'].length)
            )
          } else if (field == 'dimension_sum') {
            required = (
              (row['dimension_unit'] && row['dimension_unit'].length) &&
              this.baseDimensionFields.every(x => !row[x] || !row[x].length)
            )
          } else if (field == 'dimension_unit') {
            required = this.allDimensionFields.some(x => row[x] && row[x].length)
          }
        }
      }

      return [
        { required, message, trigger: 'change', pattern: pattern },
        { required, message, trigger: 'blur', pattern: pattern }
      ]
    }
  }
}
</script>

<style scoped>
  .el-form-fieldset{ margin-bottom: 10px; }
  .el-button-bd{ width: 160px; margin-top: 20px; }
  .fieldset-baggage{ border: 2px solid green; }
  .fieldset-carry_on{ border: 2px solid blue; }
  .fieldset-personal_item{ border: 2px solid brown; }
</style>