import gdsStore from '../../avia/general/gds_store'

export default {
  name: 'settings_limits',
  meta: {
    title: 'settings_limits',
    icon: 'el-icon-help',
    permission: 'settings_price_limit'
  },
  lazy: true,
  items: [
    {
      name: 'settings_price_limit',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'settingsPriceLimitRule',
        queries: {
          index: {
           id: true,
            oindex: true,
            departures: true,
            arrivals: true,
            rules: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'oindex',
            width: '60px'
          },
          {
            name: 'departures',
            format: 'array_as_string'
          },
          {
            name: 'arrivals',
            format: 'array_as_string'
          },
          {
            name: 'rules'
          },
          {
            name: 'updatedAt',
            width: '120px'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'TtnAviaMultiSearchService::Settings::PriceLimitRule'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'oindex',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'departures',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 3
          },
          {
            name: 'arrivals',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 3
          },
          {
            name: 'rules',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'rules__days_range',
            required: false,
            ignore: true
          },
          {
            name: 'rules__only_direct',
            type: 'checkbox',
            required: false,
            ignore: true
          },
          {
            name: 'rules__max_price',
            required: false,
            ignore: true,
            valueType: 'float'
          },
        ]
      }
    },
    {
      name: 'settings_restrictions',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'settingsRestrictionRule',
        queries: {
          index: {
            id: true,
            gdsIds: true,
            configIds: true,
            routes: true,
            daysRange: true,
            validatingSuppliers: true,
            active: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'gdsIds',
            format: 'array_as_string_options'
          },
          {
            name: 'configIds',
            format: 'array_as_string'
          },
          {
            name: 'routes'
          },
          {
            name: 'daysRange',
            width: '100px'
          },
          {
            name: 'validatingSuppliers',
            format: 'array_as_string',
            width: '120px'
          },
          {
            name: 'active',
            type: 'switch',
            width: '80px'
          },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'TtnAviaMultiSearchService::Settings::RestrictionRule'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'validating_suppliers',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'gds_ids',
            type: 'select',
            store: gdsStore.arrayById(),
            operator: 'in_array_int',
            storeLabelKeys: ['id', 'name']
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'gdsIds',
            type: 'multiselect',
            store: gdsStore.arrayById(),
            default: [],
            required: true,
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: true
          },
          {
            name: 'configIds',
            type: 'text',
            required: false,
            valueType: 'array_of_integer',
            valueDelimiter: ',',
            default: ''
          },
          {
            name: 'routes',
            type: 'text',
            required: false
          },
          {
            name: 'daysRange',
            type: 'text',
            required: false
          },
          {
            name: 'validatingSuppliers',
            type: 'text',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            default: '00'
          },
          {
            name: 'active',
            type: 'checkbox',
            required: true,
            default: true
          }
        ]
      }
    }
  ]
}
