import gdsStore from '../../../../../settings/modules/avia/general/gds_store'

export default {
  settings_price_limit: {
    table: {
      id: 'ID',
      oindex: 'Index',
      departures: 'Departures',
      arrivals: 'Arrivals',
      rules: 'Rules'
    },
    form: {
      fields: {
        id: 'ID',
        oindex: 'Index',
        departures: 'Departures',
        arrivals: 'Arrivals',
        rules: 'Rules',
        rules__daysRange: 'Days range',
        rules__onlyDirect: 'Only direct',
        rules__maxPrice: 'Max. price (EUR)',
      },
      legends: {
        rules: 'Rules'
      },
      placeholder: {
        departures: 'UA,PL (countries only)',
        arrivals: 'DE,FR (countries only)',
        rules__daysRange: '0-365',
      }
    }
  },
  settings_restrictions: {
    table: {
      id: 'ID',
      gdsIds: 'Gds ids',
      configIds: 'Config ids',
      routes: 'Routes',
      daysRange: 'Days range',
      validatingSuppliers: 'Validatings',
      active: 'Active'
    },
    form: {
      fields: {
        id: 'ID',
        gdsIds: 'Gds ids',
        configIds: 'Config ids',
        routes: 'Routes',
        daysRange: 'Days range',
        validatingSuppliers: 'Validating suppliers',
        active: 'Active'
      },
      placeholder: {
        routes: 'Empty OR UA,PL-LON;LON-MUC',
        daysRange: 'Empty OR 1-15;30-45',
        validatingSuppliers: '00 for all OR PS,DL,KL',
      },
      options: {
        gdsIds: gdsStore.objectById()
      }
    }
  }
}
