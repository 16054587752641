export default {
  name: 'seats_block_generators',
  meta: {
    title: 'Reports',
    icon: 'skill',
    permission: 'pesimisation_rules'
  },
  lazy: true,
  items: [
    {
      name: 'seats_block_generators',
      endpoint: '/persistance/default',
      type: 'graphql',
      preload: false,
      graphql: {
        name: 'aviaSeatsBlockGenerators',
        queries: {
          index: {
            id: true,
            name: true,
            handlerStatus: true,
            filePath: true,
            generateOptions: {
              configId: true,
              driverId: true,
              currency: true,
              validatingSupplier: true,
              issueOfficeId: true,
              requireSeats: true,
              departureLocation: true,
              arrivalLocation: true,
              startDate: true,
              endDate: true,
              weekDays: true,
              fares: true,
              fareFamilies: true,
              comment: true,
              contractId: true,
              checkPrice: true,
              checkConfigId: true,
              configKey: true,
              fareConditions: true,
              flightNumber: true,
              rbd: true,
              features: true,
              fileContent: true,
              locatorConfigId: true
            },
            type: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '40px'
          },
          {
            name: 'name'
          },
          {
            name: 'type',
            format: 'from_options'
          },
          {
            name: 'description'
          },
          {
            name: 'handlerStatus',
            format: 'from_options'
          },
          {
            name: 'filePath'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '90px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'success',
            plain: true,
            roles: false
          },
          {
            name: 'update',
            icon: 'el-icon-document-copy',
            type: 'info',
            plain: true
          },
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::SeatsBlock::ReportRelation'
            }
          }
        ]
      },
      // filter: {
      //   items: [
      //     {
      //       name: 'id',
      //       type: 'text'
      //     },
      //     {
      //       name: 'active',
      //       type: 'radiogroup',
      //       button: true,
      //       operator: 'eq',
      //       store: {
      //         '': 'Any',
      //         true: 'yes',
      //         false: 'no'
      //       }
      //     },
      //     {
      //       name: 'gds_id',
      //       type: 'select',
      //       operator: 'eq',
      //       storeKey: 'gds',
      //       storePrimaryKey: 'id',
      //       storeLabelKey: 'gdsName'
      //     }
      //   ]
      // },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'name',
            type: 'text',
            required: true
          },
          {
            name: 'type',
            type: 'select',
            store: {
              0: 'group_bookings',
              1: 'from_file'
            },
            translateOptions: true,
            default: '0',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__configId',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__driverId',
            type: 'select',
            store: {
              6: 'sabre',
              52: 'fly_one'
            },
            translateOptions: true,
            default: '6',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__requireSeats',
            type: 'text',
            required: true,
            valueType: 'integer'
          },

          {
            name: 'generateOptions__currency',
            type: 'text',
            required: true
          },
          {
            name: 'generateOptions__validatingSupplier',
            type: 'text',
            required: true
          },
          {
            name: 'generateOptions__issueOfficeId',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__departureLocation',
            type: 'text',
            required: true
          },
          {
            name: 'generateOptions__arrivalLocation',
            type: 'text',
            required: true
          },
          {
            name: 'generateOptions__startDate',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: true
          },
          {
            name: 'generateOptions__endDate',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: true
          },
          {
            name: 'generateOptions__weekDays',
            type: 'multiselect',
            store: [
              { id:1, name:'Mon' }, { id:2, name:'Tue' }, { id:3, name:'Wed' },
              { id:4, name:'Thu' }, { id:5, name:'Fri' }, { id:6, name:'Sat' },
              { id:0, name:'Sun' }
            ],
            storeLabelKeys: ['id', 'name'],
            default: [0,1,2,3,4,5,6]
          },
          {
            name: 'generateOptions__fares',
            type: 'text',
            required: false
          },
          {
            name: 'generateOptions__fareFamilies',
            type: 'text',
            required: false
          },
          {
            name: 'generateOptions__flightNumber',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__rbd',
            type: 'text',
            required: true
          },
          {
            name: 'generateOptions__comment',
            type: 'textarea',
            required: false,
            rows: 3,
            default: ''
          },
          {
            name: 'generateOptions__fileContent',
            type: 'textarea',
            required: false,
            rows: 9,
            default: ''
          },
          {
            name: 'generateOptions__contractId',
            type: 'text',
            required: false
          },
          {
            name: 'generateOptions__checkPrice',
            type: 'checkbox',
            required: false,
            default: false
          },
          {
            name: 'generateOptions__checkConfigId',
            type: 'text',
            required: false
          },
          {
            name: 'generateOptions__configKey',
            type: 'text',
            required: true
          },
          {
            name: 'generateOptions__fareConditions',
            type: 'textarea',
            required: false,
            rows: 3,
            default: ''
          },
          {
            name: 'generateOptions__fares__name',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__fare',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__taxes',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__total',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__fareCode',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__baggage',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__baggage_details__dimension_sum',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__baggage_details__dimension_unit',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__baggage_details__weight',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__fares__baggage_details__weight_unit',
            required: false,
            ignore: true
          },
          {
            name: 'generateOptions__fares__baggage_details__count',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'generateOptions__features',
            type: 'text',
            required: false
          },
          {
            name: 'generateOptions__features__isLcc',
            type: 'checkbox',
            required: false,
            default: false,
            ignore: true
          },
          {
            name: 'generateOptions__features__isCharter',
            type: 'checkbox',
            required: false,
            default: false,
            ignore: true
          },
          {
            name: 'generateOptions__features__showSeatsCount',
            type: 'checkbox',
            required: false,
            default: false,
            ignore: true
          },
          {
            name: 'generateOptions__locatorConfigId',
            type: 'text',
            required: false
          },
        ]
      }
    }
  ]
}
