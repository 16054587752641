<template lang="pug">
  .avia-booking-list-table.list-content.pc_list
    el-dialog(:visible.sync="centerDialogVisible" center title="Booking Comments")
      el-row
        el-col
          el-table(:data="comments[locator]")
            el-table-column(prop="backendUser.login" width="100px")
            el-table-column
              template(slot-scope="scope")
                div(v-html="scope.row.data")
            el-table-column(width="200px")
              template(slot-scope="scope")
                span {{new Date(scope.row.createdAt) | moment("LLL")}}
      el-row(:gutter="20")
        el-col(:span="16")
          el-input(type="textarea" v-model="comment" :autosize="{ minRows: 7, maxRows: 15 }")
        el-col(:span="6")
          el-row
            el-checkbox(v-model="attentionComment") Add as notice
          el-row
            el-checkbox(v-model="mcoGeneralComment") MCO general comment
          el-row
            el-button(@click="addComment" type="primary") Add Comment
      el-button(slot="footer" @click="centerDialogVisible = false") Close

    el-container(style="max-height: calc(100vh - 245px)")
      el-header(height="30px")
        top-menu(:currentRow="currentRow" :navigateTab="navigateTab" style="padding: 0 0 0 4px")
      el-main
        el-table(
          :data="records"
          :row-class-name="tableRowClassName"
          v-loading="loading"
          @row-dblclick="showDetails"
          highlight-current-row
          @current-change="handleCurrentChange"
        )
          el-table-column(min-width="231" max-width="300")
            template(slot-scope="scope")
              el-row
                el-col
                  span.upcase.pointer(@click.prevent="setEditLocator(scope.row.id, scope.row.locator)")
                    template Locator:&nbsp;
                    span.bold(v-if="editLocators[scope.row.id] == undefined") {{ scope.row.locator }}
                    el-input(v-else type="string" v-model="editLocators[scope.row.id]" style="width: 140px")
                      template(slot="append")
                        el-button-group(style="display: inline-flex")
                          el-button(icon="el-icon-check" plain type="success" @click.stop="saveLocator(scope.row.id)")
                          el-button(icon="el-icon-close" plain type="danger" @click.stop="editLocators[scope.row.id] = undefined")
              el-row
                el-col ID: {{ scope.row.id }}
              el-row
                el-col.upcase
                  template STATUS:&nbsp;
                  span.blue.bold {{ scope.row.status }}
              el-row
                el-col.upcase {{ generateTripRoute(scope.row.locations) }}
              el-row
                el-col.upcase DEP {{ scope.row.departureTime }}
          el-table-column(min-width="200")
            template(slot-scope="scope")
              el-row
                el-col Depot: {{ scope.row.depot.name }} [{{ scope.row.depot.id }}]
              el-row
                el-col
                  template Refid:&nbsp;
                  span.bold {{ scope.row.trafficSource ? scope.row.trafficSource.name + ' [' + scope.row.trafficSource.id + ']' : "-" }}
              el-row
                el-col Created at: {{ new Date(scope.row.createdAt) | moment("YYYY-MM-DD HH:mm") }}
              el-row
                el-col Timelimit: {{ new Date(scope.row.timelimit) | moment("YYYY-MM-DD HH:mm") }}
          el-table-column(width="400")
            template(slot-scope="scope")
              div(v-if="scope.row.testOrder" style="color: red") TEST
              span {{ setComments(scope.row, scope.row.locator) }}
              .pointer(@click="showComments(scope.row.locator)")
                el-row
                  el-col {{ getLastCommentAuthor(scope.row.locator) }}
                el-row
                  el-col.short(v-html="getLastCommentText(scope.row.locator)")
</template>

<script>
import BaseTable from '@crud_view/table'
import TopMenu from './top_menu'

export default {
  components: {
    TopMenu
  },
  extends: BaseTable,
  data() {
    return {
      loading: false,
      comments: {},
      centerDialogVisible: false,
      locator: 0,
      comment: '',
      currentRow: null,
      attentionComment: false,
      mcoGeneralComment: false,
      editLocators: {}
    }
  },

  methods: {
    addComment() {
      if (this.comment.length === 0) {
        return
      } else {
        this.proceedComment()
      }
    },

    async proceedComment() {
      try {
        const query = {
          __args: {
            locator: this.locator,
            comment: this.comment,
            table: 'avia_bookings',
            attentionComment: this.attentionComment,
            mcoGeneralComment: this.mcoGeneralComment
          },
          id: true,
          createdAt: true
        }

        await this.graphql('addComment', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Сomment added',
          duration: 2600
        })

        this.comment = ''
        this.attentionComment = false
        this.mcoGeneralComment = false

        this.navigateTab({
          reload: true,
          tab: 'items',
          item: 'avia_booking_list'
        })
      } catch (e) {
        console.error(e)
      }
    },

    generateTripRoute(segments) {
      var route = []
      segments.forEach((segment) => {
        if (route[route.length - 1] === undefined) {
          route.push(segment.departureLocation)
        } else {
          if (route[route.length - 1] !== segment.departureLocation) {
            route.push(segment.departureLocation)
          }
        }
        route.push(segment.arrivalLocation)
      })
      return route.join(' — ')
    },

    getLastCommentAuthor(locator) {
      if (this.comments[locator] === null || this.comments[locator].length === 0) {
        return ''
      }
      var comment = this._.first(this.comments[locator])

      return this.$moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss') + ' ' + comment.backendUser.login
    },

    getLastCommentText(locator) {
      if (this.comments[locator] === null || this.comments[locator].length === 0) {
        return ''
      }
      var comment = this._.first(this.comments[locator])

      if (comment === null) {
        return ''
      }

      return comment.data
    },

    setComments(booking, locator) {
      this.comments[locator] = []

      if (booking === undefined || booking === null) {
        return
      }

      if (booking.comments === undefined) {
        return
      }

      this.comments[locator] = [...booking.comments]
    },

    showComments(locator) {
      this.locator = locator
      this.centerDialogVisible = true
      this.comment = ''
    },

    tableRowClassName({ row, rowIndex }) {
      if (row == null) {
        return ''
      } else if (row.testOrder) {
        return 'status_row_test'
      } else {
        return 'status_row_' + row.status
      }
    },

    showDetails(row) {
      this.navigateTab({
        tab: 'items',
        item: 'avia_booking_show',
        reload: true,
        filters: {
          bookings: [
            {
              field: 'id', operator: 'eq', value: row.id
            }
          ]
        }
      })
    },

    handleCurrentChange(val) {
      if (!val) return
      this.currentRow = val
      this.$elItem.$emit('showInfo', val)
    },

    setEditLocator(bookingId, state) {
      this.editLocators = { ...this.editLocators, [bookingId]: state }
    },

    async saveLocator(bookingId) {
      try {
        const query = {
          __args: {
            id: bookingId,
            newLocator: this.editLocators[bookingId]
          }
        }

        await this.graphql('aviaBookingItemSaveLocator', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Locator saved',
          duration: 2600
        })

        this.editLocators = { ...this.editLocators, [bookingId]: null }

        this.navigateTab({
          reload: true,
          tab: 'items',
          item: 'avia_booking_list'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.avia-booking-list-table {
  .upcase {
    text-transform: uppercase;
  }

  .right {
    text-align: right;
  }

  .small {
    font-size: smaller;
  }

  .cell {
    font-size: inherit;
    line-height: inherit;
  }

  .el-table {
    color: #334154;
    font-size: 13px;
    line-height: 16px;
    &__header-wrapper {
      display: none;
    }
    &__body {
      border-collapse: collapse;
    }
    &__cell {
      vertical-align: top;
    }
    &__row {
      &[class*='status_row'] {
        & > .el-table__cell:first-child {
          border-left: 4px solid transparent;
        }
        &.current-row > .el-table__cell:first-child {
          border-left-color: red;
        }
      }
      &.status_row {
        &_W {
          background: #80ffff;
        }
        &_P {
          background: #9eff7f;
        }
        &_PN {
          background: #ffe681;
        }
        &_PN_autotckt {
          background: #cccc99;
        }
        &_CM {
          background: #e5cbf1;
        }
        &_CMO {
          background: #f1e0cb;
        }
        &_CMP {
          background: #cbd3f1;
        }
        &_CMW {
          background: #9eff7f;
        }
        &_NP {
          background: #70aae9;
        }
        &_WA {
          background: #b97a57;
        }
        &_LCP {
          background: #00aee1;
        }
        &_manual_book {
          background: #e1f3d8;
        }
        &_test {
          background: #ffcfcf;
        }
      }
      &.grey {
        background: #f2f6fc;
        border-bottom: 3px solid white;
      }
    }
  }
}

$checkbox-props: "[prop='urgent'],[prop='not_actually'],[prop='booking_by_legal_person'],[prop='gmt_only'],[prop='with_special_fare'],[prop='with_special_fare_search'],[prop='fare_downgrade'],[prop='has_ancillary_services'],[prop='has_fare_family'],[prop='from_baggage_tab'],[prop='manual_emd'],[prop='baggage_gds'],[prop='baggage_tab_manual'],[prop='not_urgent'],[prop='urgent_h'],[prop='is_rebooked'],[prop='explorer_check'],[prop='price_component_partially_issued'],[prop='exclude_tickets_kz'],[prop='ground_segments'],[prop='infants'],[prop='is_charter'],[prop='test_order'],[prop='exclude_conv_booking'],[prop='conv_booking'],[prop='self_transfers'],[prop='is_multi_fare'],[prop='is_multi'],[prop='is_multi_gds'],[prop='with_auction'],[prop='ttn_avia_vi'],[prop='with_dynamic_commission'],[prop='price_component_gte'],[prop='price_component_lte'],[prop='is_recommended'],[prop='all_fake_booking'],[prop='all_fake_success'],[prop='fake_only_in_process'],[prop='fake_exclude_in_process'],[prop='fake_only_with_errors'],[prop='fake_pnr_excluded'],[prop='fake_recc_excluded'],[prop='exclude_conso_queue'],[prop='exclude_backlog_queue'],[prop='exclude_autoticketing_queue'],[prop='manual_processing_queue']";

#pane-avia_booking_list {
  div:is(#{$checkbox-props}) span.el-checkbox__input {
    top: 10px;
    left: 10px;
  }

  div.el-form-item {
    &:has(#{$checkbox-props}) {
      .el-form-item__content {
        display: inline-block;
      }
      .el-form-item__label {
        width: 130px;
      }
    }
    &:has([prop='urgent_h'], [prop='price_component_gte'], [prop='price_component_lte']) {
      .el-form-item__content,
      .el-form-item__label {
        width: 75px;
      }
      .el-input {
        top: 10px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.avia-booking-list-table {
  hr {
    border: 0;
    border-top: 1px dotted black;
  }

  .wrap:last-child {
    margin-bottom: 5px;
  }

  .el-dialog .el-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .el-card:not(:last-child),
  .el-card .el-row:not(:last-child) {
    margin-bottom: 5px;
  }
}
</style>
