export default {
  settings_list: {
    table: {
      id : 'ID',
      depotId: 'Depot',
      refIds: 'refIds',
      markets: 'markets',
      insuranceConnection: 'Connection',
      insurancePackets: 'Packets',
      service: 'Service',
      enabled: 'Enabled',
    },
    form: {
      fields: {
        index: 'Index',
        depotId: 'Depot ID',
        refIds: 'refIds',
        markets: 'markets',
        insuranceConnectionId: 'Connection ID',
        service: 'Service',
        enabled: 'Enabled',
      }
    }
  }
}
