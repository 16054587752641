export default {
  stations_list: {
    table: {
      Id: 'ID',
      code: 'Code',
      name: 'Name',
      seo: 'Seo URL',
      busSeo: 'Bus Seo URL',
      parentCode: 'Parent Code',
      replaceCode: 'Replace Code',
      geoCityCode: 'City Code',
      geoCountryCode: 'Country Code',
      iataCode: 'IATA Code',
      status: 'Status',
      score: 'Score',
      latitude: 'Coordinates',
      longitude: 'Longitude',
      railRoadId: 'Rail Road'
    },
    form: {
      fields: {
        code: 'Code',
        name: 'Name',
        seo: 'Seo URL',
        busSeo: 'Bus Seo URL',
        parentCode: 'Parent Code',
        replaceCode: 'Replace Code',
        geoCityCode: 'City Code',
        geoCountryCode: 'Country Code',
        iataCode: 'IATA Code',
        latitude: 'Latitude',
        longitude: 'Longitude',
        score: 'Score',
        status: 'Status',
        railRoadId: 'Rail Road ID'
      }
    }
  },
  station_synonyms_list: {
    table: {
      id: 'ID',
      name: 'Synonyms',
      locale: 'Locale',
      status: 'Status'
    },
    form: {
      fields: {
        name: 'Name',
        locale: 'Locale',
        status: 'Status'
      }
    }
  },
  station_cities_list: {
    table: {
      id: 'ID',
      name: 'City Name',
      locale: 'Locale',
      status: 'Status'
    },
    form: {
      fields: {
        name: 'Name',
        locale: 'Locale',
        status: 'Status'
      }
    }
  },
  station_providers_list: {
    table: {
      id: 'ID',
      providerName: 'Provider Name',
      providerCode: 'Provider Code',
      status: 'Status'
    },
    form: {
      fields: {
        providerName: 'Provider Name',
        providerCode: 'Provider Code',
        status: 'Status'
      }
    }
  }
}