<template lang="pug">
  el-dialog(
    :visible.sync="editCartCustomDiffDialog.visible"
    :title="editCartCustomDiffDialog.title"
    center
    @closed="handleClose"
  )
    el-row
      el-form(v-if="cart" :model="diffForm" ref="customDiffForm" :inline="true" :rules="rules")
        el-form-item( style="display: none;" prop="locator")
          el-input(v-model="diffForm.locator")
        el-form-item( label="Custom diff" prop="diff")
          el-input-number(v-model="diffForm.diff" :step="1" placeholder="Enter diff" :controls="false")
        el-form-item
          el-button(@click="handleSave" type="primary" :disabled="diff == diffForm.diff") Save
        el-form-item
          el-button(@click="handleClose") Close

    el-row
      el-table(v-if="cart && cart.details && cart.details['custom_diffs']" :data="cart.details['custom_diffs']")
        el-table-column(label="At" width="200px" prop='at')
        el-table-column(label="Author" width="200px" prop='updated_by')
        el-table-column(label="From" width="200px" prop='from')
        el-table-column(label="To" width="200px" prop='to')

</template>

<script>
  import common from '@crud_lib/common'
  import di from '@crud_lib/di'

  export default {
    mixins: [common, di],
    data() {
      return {
        editCartCustomDiffDialog: {
          title: 'Edit Cart Diff (this amount will be sent to user)',
          visible: false,
        },
        histories: [],
        diff: 0.0,
        cart: null,
        diffForm: {
          diff: 0,
          locator: null
        },
        rules: {
          diff: [
            {
              validator: (rule, value, callback) => {
                if (value === null || value === undefined || value === '') {
                  callback(new Error('This field is required'));
                } else if (isNaN(value) || parseFloat(value) != value) {
                  callback(new Error('Please enter a valid float number'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
        }
      }
    },
    methods: {
      show(cart) {
        this.editCartCustomDiffDialog.visible = true
        this.reloadData(cart)
      },
      handleClose() {
        this.editCartCustomDiffDialog.visible = false
      },
      handleSave() {
        let self = this
        this.$refs.customDiffForm.validate((valid) => {
          if (valid) {
            self.saveCustomDiff()
            self.handleClose()
          }else{
            return false  
          }
        })
      },
      async saveCustomDiff() {
        try {
          const query = {
            __args: {
              locator: this.diffForm.locator,
              diff:    this.diffForm.diff
            },
          }

          await this.graphql('aviaBookingCartSaveCustomDiff', query, 'mutation')

          this.getCart()

        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      },

      async getCart(){
        try {
          const query = {
            __args: {
              locator: this.diffForm.locator
            },
            id: true,
            status: true,
            locator: true,
            createdAt: true,
            updatedAt: true,
            items: true,
            totals: true,
            details: true
          }

        let cart = await this.graphql('aviaBookingCartGet', query)

        this.$emit('childEvent', cart)

        } catch (e) {
          console.error(e)
        }
      },

      reloadData(cart) {
        this.cart      = { ...cart }
        this.histories = { ...(cart.details['custom_diffs'] || []) }
        this.diff      = cart.totals['custom_diff']
        this.diffForm  = { ...{diff: cart.totals['custom_diff'], locator: this.cart.locator} }
      },

    }
  }
</script>