export default {
  namespace: 'travel_rankings',
  component: 'admin',
  meta: {
    title: 'travel_rankings.admin',
    icon: 'table'
  },
  tabs: [
    {
      name: 'hot_settings',
      items: [
        {
          name: 'tracking',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'rankingsTrackingSettings',
            queries: {
              index: {
                id: true,
                market: true,
                duration: true,
                active: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              {
                name: 'id',
                width: '80px'
              },
              'market',
              'duration',
              {
                name: 'active',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              },
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                context: 'panel',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Rankings::TrackingSettingRelation'
                }
              },
              {
                name: 'filter',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            fieldsets: [
              {
                legend: 'default',
                items: [
                  {
                    name: 'market',
                    type: 'text',
                    operator: 'like',
                    scope: 'rankingsTrackingSettings'
                  },
                ]
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'market',
                    type: 'text'
                  },
                  {
                    name: 'duration',
                    type: 'text'
                  },
                  {
                    name: 'active',
                    type: 'checkbox',
                    default: false,
                    required: false
                  }
                ]
              }
            ]
          }
        },
        {
          name: 'global',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'rankingsHotSettings',
            queries: {
              index: {
                id: true,
                key: true,
                value: true,
                active: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              {
                name: 'id',
                width: '80px'
              },
              'key',
              'value',
              {
                name: 'active',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              },
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                context: 'panel',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Rankings::HotSettingRelation'
                }
              },
              {
                name: 'filter',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            fieldsets: [
              {
                legend: 'default',
                items: [
                  {
                    name: 'key',
                    type: 'text',
                    operator: 'like',
                    scope: 'rankingsHotSettings'
                  },
                ]
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'key',
                    type: 'text'
                  },
                  {
                    name: 'value',
                    type: 'text'
                  },
                  {
                    name: 'active',
                    type: 'checkbox',
                    default: false,
                    required: false
                  }
                ]
              }
            ]
          }
        }
      ]
    },
    {
      name: 'settings',
      items: [
        {
          name: 'logs',
          endpoint: '/persistance/rankings',
          lazy: true,
          type: 'graphql',
          graphql: {
            name: 'projectLogs',
            queries: {
              index: {
                file: true,
                id: true,
                logDate: true,
                message: true,
                requestType: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              'id',
              'updated_at',
              'request_type',
              'message',
              {
                name: 'expand',
                type: 'expand'
              }
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'refresh',
                context: 'panel',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'log_date',
                type: 'date',
                operator: 'eq',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: 'today',
                required: true
              },
              {
                name: 'request_type',
                type: 'select',
                store: { admin: 'Admin', api: 'Api', sidekiq: 'Sidekiq' },
                default: 'api',
                operator: 'eq',
                required: true
              }
            ]
          },
          form: {
            items: []
          }
        },
        {
          name: 'airports',
          endpoint: '/persistance/rankings',
          type: 'graphql',
          lazy: true,
          graphql: {
            name: 'airports',
            queries: {
              index: {
                id: true,
                code: true,
                tzName: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'code',
              'tzName',
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '60px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'code',
                operator: 'eq',
                type: 'text'
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'code',
                    type: 'text'
                  },
                  {
                    name: 'tzName',
                    type: 'text'
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  ]
}