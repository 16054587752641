export default {
  global: {
    table: {
      key: 'Key',
      value: 'Value',
      active: 'Active'
    },
    form: {
      legends: {
        default: 'Filters'
      },
      fields: {
        key: 'Key',
        value: 'Value',
        active: 'Active'
      }
    }
  },
  tracking: {
    table: {
      market: 'Market',
      duration: 'Duration (hours)',
      active: 'Active'
    },
    form: {
      legends: {
        default: 'Filters'
      },
      fields: {
        market: 'Market',
        duration: 'Duration in hours',
        active: 'Active'
      }
    }
  }
}
