export default {
  name: 'settings',
  lazy: true,
  items: [
    {
      name: 'settings_list',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      graphql: {
        name: 'insuranceSettings',
        queries: {
          index: {
            id: true,
            depotId: true,
            refIds: true,
            markets: true,
            service: true,
            enabled: true,
            insuranceConnection: {
              id: true,
              nameAlias: true
            },
            insurancePackets: {
              id: true,
              providerName: true
            }
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '70px',
            align: 'right'
          },{
            name: 'depotId',
            format: 'from_boot',
            storeKey: 'depots_hash',
            storeLabelKey: 'name'
          }, 'refIds', 'markets', 'service', {
            name: 'insuranceConnection',
            align: 'center'
          },{
            name: 'enabled',
            type: 'switch'
          },{
            name: 'insurancePackets',
            align: 'center'
          },{
            name: 'actions',
            width: '70px',
            align: 'center'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },{
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Legacy::Insurance::SettingRelation'
            }
          },{
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'depot_id',
            operator: 'eq',
            type: 'select',
            storeKey: 'depots',
            storeLabelKeys: ['id', 'name'],
            lazy: true
          },{
            name: 'service',
            operator: 'eq',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              avia: 'avia',
              railway: 'railway',
              bus: 'bus',
              insurance: 'insurance',
            },
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false
          },{
            name: 'depot_id',
            type: 'select',
            storeKey: 'depots',
            storeLabelKeys: ['id', 'name'],
            lazy: true,
            required: true
          },{
            name: 'refIds',
            type: 'text',
            required: false
          },{
            name: 'markets',
            type: 'text',
            required: false
          },{
            name: 'service',
            type: 'select',
            store: {
              avia: 'avia',
              raiway: 'railway',
              bus: 'bus',
              insurance: 'insurance',
            },
            required: true
          },{
            name: 'enabled',
            type: 'switch',
            default: true,
            required: true
          },

        ]
      }
    }
  ]
}
